.pac-item {
  background-color: transparent;
  white-space: normal !important;
  line-height: normal;
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pac-icon {
  display: none;
}
